import { render, staticRenderFns } from "./moreTours.vue?vue&type=template&id=4b70c687&scoped=true&"
import script from "./moreTours.vue?vue&type=script&lang=js&"
export * from "./moreTours.vue?vue&type=script&lang=js&"
import style0 from "./moreTours.vue?vue&type=style&index=0&id=4b70c687&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b70c687",
  null
  
)

export default component.exports